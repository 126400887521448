import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { ApolloProvider } from "@apollo/client";

import { buildApolloClient, LoadingStatus } from "../common/Network";
import Login from "../login/Login";
import Welcome from "./Welcome";
import "./PaintApp.css";

const Browse = lazy(() => import("./browse/Browse"));
const Filter = lazy(() => import("./filter/Filter"));

export default function PaintApp() {
  return (
    <ApolloProvider client={buildApolloClient()}>
      <Router>
        <Container
          id="paint-app"
          className="PaintApp d-flex h-100 flex-column p-3"
          fluid
        >
          <Suspense fallback={<LoadingStatus />}>
            <Login>
              <Switch>
                <Route exact path="/" component={Welcome} />
                <Route component={PageWithHeader} />
              </Switch>
            </Login>
          </Suspense>
        </Container>
      </Router>
    </ApolloProvider>
  );
}

function PageWithHeader() {
  return (
    <div>
      <header id="paint-app-header">
        <h1>涂料行业有毒有害物质数据信息系统</h1>
        <Link to="/">返回首页</Link>
      </header>
      <div id="paint-app-content">
        <Switch>
          <Route exact path="/browse/" component={Browse} />
          <Route exact path="/filter/" component={Filter} />
        </Switch>
      </div>
    </div>
  );
}

