import React, { useState, useEffect } from "react";
import { Alert, Modal, Button, Form, Spinner } from "react-bootstrap";

import { useTokenAuth, useRefreshToken, TokenAuth } from "./Query";
import { LoadingStatus } from "../common/Network";
import "./Login.css";

const TOKEN_KEY = "GRAPHQL_JWT_TOKEN_AUTH";

export function getToken(): { token: string; needRefresh: boolean } | null {
  const data = JSON.parse(
    sessionStorage.getItem(TOKEN_KEY) ||
      localStorage.getItem(TOKEN_KEY) ||
      "null"
  );
  if (!data) return null;
  const tokenAuth = data as TokenAuth;
  if (Date.now() / 1000 <= tokenAuth.payload.exp) {
    return { token: tokenAuth.token, needRefresh: false };
  }
  if (Date.now() / 1000 <= tokenAuth.refreshExpiresIn) {
    return { token: tokenAuth.token, needRefresh: true };
  }
  return null;
}

function setToken(
  authData: TokenAuth,
  keepLogin: boolean | undefined = undefined
) {
  keepLogin =
    keepLogin !== undefined
      ? keepLogin
      : localStorage.getItem(TOKEN_KEY) !== undefined;
  const data = JSON.stringify(authData);
  (keepLogin ? sessionStorage : localStorage).removeItem(TOKEN_KEY);
  (keepLogin ? localStorage : sessionStorage).setItem(TOKEN_KEY, data);
}

export function logout() {
  sessionStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_KEY);
  window.location.reload(false);
}

function Login(props: { children: React.ReactNode }) {
  const [refreshToken, { data: refreshResult, loading }] = useRefreshToken();
  const [myToken, setMyToken] = useState(getToken());

  if (loading) return <LoadingStatus />;
  if (refreshResult) {
    setToken(refreshResult.refreshToken);
    return <>{props.children}</>;
  }

  function onNewToken(newToken: TokenAuth, keepLogin: boolean) {
    setToken(newToken, keepLogin);
    setMyToken(getToken());
  }

  if (!myToken) return <LoginModal onNewToken={onNewToken} />;
  if (myToken.needRefresh) {
    refreshToken({ variables: { token: myToken.token } });
    // TODO: handle error on refresh token
    return <LoadingStatus />;
  }
  return <>{props.children}</>;
}

type LoginModalProps = {
  onNewToken: (token: TokenAuth, keepLogin: boolean) => void;
};

function LoginModal(props: LoginModalProps) {
  const [authError, setAuthError]: [
    any,
    React.Dispatch<React.SetStateAction<any>>
  ] = useState();
  const [tokenAuth, { data: authResult, loading }] = useTokenAuth();
  const [keepLogin, setKeepLogin] = useState(true);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  function onInputFieldChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    if (target.name === "keepLogin") {
      setKeepLogin(target.checked);
    } else {
      setCredentials({ ...credentials, [target.name]: target.value });
    }
  }

  async function onSubmit(event: React.FormEvent) {
    event.preventDefault();
    try {
      await tokenAuth({ variables: credentials });
    } catch (err) {
      // workaround
      // https://github.com/apollographql/apollo-client/issues/6070
      setAuthError(err);
    }
  }

  useEffect(() => {
    if (authResult) props.onNewToken(authResult.tokenAuth, keepLogin);
  });
  if (authResult) return null;

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>用户登录</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {authError && (
          <Alert
            variant="warning"
            onClose={() => setAuthError(undefined)}
            dismissible
          >
            {authError.message}
          </Alert>
        )}
        <Form id="Login-form" onSubmit={onSubmit}>
          <Form.Group controlId="username">
            <Form.Label>用户名</Form.Label>
            <Form.Control
              name="username"
              required
              placeholder="请输入用户名"
              value={credentials.username}
              onChange={onInputFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>密码</Form.Label>
            <Form.Control
              name="password"
              required
              type="password"
              placeholder="请输入密码"
              value={credentials.password}
              onChange={onInputFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="keepLogin">
            <Form.Check
              type="checkbox"
              label="记住登录"
              name="keepLogin"
              checked={keepLogin}
              onChange={onInputFieldChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          variant="primary"
          form="Login-form"
          disabled={loading}
        >
          {loading && (
            <Spinner as="span" animation="grow" size="sm" role="status" />
          )}
          登录
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Login;
