import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { Spinner } from "react-bootstrap";
import { setContext } from "@apollo/client/link/context";

import { getToken } from "../login/Login";

export function buildApolloClient() {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPH_QL_URL,
  });
  const authLink = setContext((_, { headers }) => {
    const result = getToken();
    const token = result?.needRefresh ? null : result?.token;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });
  const cache = new InMemoryCache({
    typePolicies: {
      SubstanceType: {
        keyFields: ["casNo"],
      },
    },
  });
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: cache,
  });
}

export function LoadingStatus() {
  return (
    <Spinner animation="border" variant="secondary" role="status">
      <span className="sr-only">载入中…</span>
    </Spinner>
  );
}
