import React from "react";
import { Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Icon, InlineIcon } from "@iconify/react";

import iconList from "@iconify/icons-mdi/format-list-text";
import iconSearch from "@iconify/icons-mdi/database-search";

import "./Welcome.css";

export default function Welcome() {
  return (
    <>
      <Helmet>
        <title>涂料行业有毒有害物质数据信息系统</title>
      </Helmet>
      <div></div>

      <Row
        id="paint-app-welcome-main"
        className="justify-content-md-center flex-d"
      >
        <h1>
          <span lang="en">Coatings</span>涂料行业有毒有害物质数据信息系统
        </h1>
        <ol className="d-flex">
          <li>
            <Link to="/browse/" title="浏览、筛选所有物质">
              <Icon icon={iconList} width="64px" />
              浏览查找
            </Link>
          </li>
          <li>
            <Link to="/filter/" title="分类统计">
              <Icon icon={iconSearch} width="64px" />
              分类统计
            </Link>
          </li>
        </ol>
      </Row>

      <Row>
        <footer id="paint-app-welcome-footer" className="col d-flex">
          <div>
            Copyright © 2021–2025
            <br />
            涂料行业有毒有害物质筛选、排放特征与场景构建研究
          </div>
          <div>
            <Link to="#TODO">免责条款</Link>
          </div>
        </footer>
      </Row>
    </>
  );
}
